import React, { useEffect, useState } from 'react';
import {jwtDecode} from 'jwt-decode'; // Corrected import
import './App.css';

function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [error, setError] = useState(null);
  const [copyMessage, setCopyMessage] = useState('');

  useEffect(() => {
    const loadUserInfo = () => {
      try {
        const idToken = localStorage.getItem('idToken');
        const accessToken = localStorage.getItem('accessToken');

        if (!idToken || !accessToken) {
          throw new Error('No tokens found. Please log in.');
        }

        // Decode tokens
        const idTokenDecoded = jwtDecode(idToken);
        const accessTokenDecoded = jwtDecode(accessToken);

        setUserInfo({
          idToken: idTokenDecoded,
          accessToken: accessTokenDecoded,
          rawTokens: {
            idToken,
            accessToken,
          },
        });
      } catch (err) {
        setError(err.message);
      }
    };

    loadUserInfo();
  }, []);

  const copyToClipboard = (text, type) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyMessage(`${type} copied to clipboard!`);
      setTimeout(() => setCopyMessage(''), 3000); // Clear message after 3 seconds
    });
  };

  if (error) {
    return (
      <div className="error-container">
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  if (!userInfo) {
    return <div className="loading-container">Loading user information...</div>;
  }

  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome, Developer!</h1>
        <p>Below is the user and token information for debugging purposes.</p>

        {copyMessage && <p className="copy-message">{copyMessage}</p>}

        <div className="token-section">
          <h2>Decoded ID Token</h2>
          <pre className="token-data">{JSON.stringify(userInfo.idToken, null, 2)}</pre>
          <button
            className="copy-button"
            onClick={() => copyToClipboard(userInfo.rawTokens.idToken, 'ID Token')}
          >
            Copy ID Token
          </button>
        </div>

        <div className="token-section">
          <h2>Decoded Access Token</h2>
          <pre className="token-data">{JSON.stringify(userInfo.accessToken, null, 2)}</pre>
          <button
            className="copy-button"
            onClick={() => copyToClipboard(userInfo.rawTokens.accessToken, 'Access Token')}
          >
            Copy Access Token
          </button>
        </div>

        <div className="token-section">
          <h2>Raw Tokens</h2>
          <pre className="token-data">{JSON.stringify(userInfo.rawTokens, null, 2)}</pre>
        </div>
      </header>
    </div>
  );
}

export default App;
