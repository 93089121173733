import React, { useEffect, useState } from 'react';

const Callback = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTokens = async () => {
      const params = new URLSearchParams(window.location.search);
      const code = params.get('code');

      if (!code) {
        setError('No authorization code found in the URL.');
        return;
      }

      const requestBody = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: 'lssp131hd54mt1c5nm63c2159',
        client_secret: 'qr3jo5q2msk308ulbgt8hng6j1ap3rhccd1ophmq0u0dti5cvue',
        redirect_uri: 'http://localhost:3000/callback',
        code,
      });

      try {
        const response = await fetch('https://auth.development.mywillum.com/oauth2/token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          body: requestBody,
        });

        if (!response.ok) {
          const errorResponse = await response.json();
          throw new Error(`Token request failed: ${errorResponse.error_description || response.statusText}`);
        }

        const tokens = await response.json();

        // Store tokens in localStorage (or React Context)
        localStorage.setItem('idToken', tokens.id_token);
        localStorage.setItem('accessToken', tokens.access_token);
        localStorage.setItem('refreshToken', tokens.refresh_token);

        // Redirect to the home page
        window.location.href = '/';
      } catch (err) {
        setError(err.message || 'An unexpected error occurred while fetching tokens.');
      }
    };

    fetchTokens();
  }, []);

  if (error) {
    return (
      <div style={{ padding: '20px', fontFamily: 'Arial, sans-serif', color: 'red' }}>
        <h2>Error</h2>
        <p>{error}</p>
      </div>
    );
  }

  return <div>Processing your login...</div>;
};

export default Callback;
